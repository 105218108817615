<template>
	<div class="bg_dark bg_intro_1">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character1">
							<div class="text_guide pt-20">
								<h4>허용 안내</h4>
							</div>
							<div class="text_guide_desc pt-15">
								<p>서비스 이용을 위해<br>
									아래 접근권한을 허용해 주세요</p>
							</div>
						</div>
					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
							<button class="btn_l btn_fill_gray" @click="$emit('goBack')">취소</button>
							<button class="btn_l btn_fill_blue" @click="checkConfirm">확인</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia001'
	,data: function(){
		return {
			program: {
				name: '앱 접금 권한 안내'
				, not_header: true
				, not_footer: true
			}
		}
	}
	,methods: {
		checkConfirm: async function(){
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>